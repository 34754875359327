
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      type: "",
      contact_limit: "",
      name: "",
      amount: "",
      number_limit: "",
      sms_limit: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);

    const page = ref<string>("");

    // const value = ref('');
    const registration = Yup.object().shape({
      type: Yup.string().required().label("Plan Type"),
      name: Yup.string().required().label("Name"),
      contact_limit: Yup.number()
        .typeError("please specify number")
        .label("Number of Contact"),
      number_limit: Yup.number()
        .typeError("please specify number")
        .required()
        .label("Number Limit"),
      amount: Yup.number()
        .typeError("please specify number")
        .required()
        .label("Amount"),
      sms_limit: Yup.number()
        .typeError("please specify number")
        .label("Number of Text"),
    });

    onMounted(() => {
      const userData = localStorage.getItem("userdata");
      if (userData) {
        var allData = JSON.parse("" + userData + "");
        if (allData.type == "admin") {
          const data = route.params.id;
          if (data) {
            // getUser(id);
            // page.value = "Edit";
            setCurrentPageBreadcrumbs("Edit Plan", []);
          } else {
            // page.value = "Add";
            setCurrentPageBreadcrumbs("Add Plan", []);
          }

          store.dispatch(Actions.GET_AUTH_USER).then((data) => {
            //formData.value = data
            profile.value = data;
          });
          nextTick(() => {
            PasswordMeterComponent.bootstrap();
          });
          const id = route.params.id;
          if (id) {
            // getUser(id);
            page.value = "Edit";
          } else {
            page.value = "Add";
          }
        } else {
          router.push({ name: "dashbord" });
        }
      }
    });
    const getUser = (id) => {
      console.log("test");
    };
    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      console.log(values);
      //  console.log('values',values);
      if (values.name.trim() == "") {
        Swal.fire({
          text: "Please enter valid name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.amount <= 0) {
        Swal.fire({
          text: "Please Make sure amount!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.type == 'plan' && ( (values.contact_limit && values.contact_limit.trim() == "") ||  (!values.contact_limit) )) {
        Swal.fire({
          text: "Number of Contact is required!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.type == 'plan' && ( (values.sms_limit && values.sms_limit.trim() == "") || (!values.sms_limit) )) {
        Swal.fire({
          text: "Number of Text is required!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }else {
        loading.value = true;
        var request = {
          url: "plans",
          data: {
            name: values.name,
            type: values.type,
            amount: values.amount,
            contact_limit: values.contact_limit,
            sms_limit: values.sms_limit,
            number_limit: values.number_limit,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "Plan added successfully!", "success");
              router.push({ name: "admin-plan" });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      submitButton.value?.removeAttribute("data-kt-indicator");
      submitButton.value!.disabled = false; 
    };
    const changePlan = (event) =>{
      if(event.target.value == 'addons'){
        // formData.value.contact_limit = '1'
        // formData.value.sms_limit = '1'
      }else{
        
        // formData.value.contact_limit = ''
        // formData.value.sms_limit = ''
      }
      //console.log(formData.value);
    }
    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      page,
      formData,
      changePlan
    };
  },
});
